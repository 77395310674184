import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationEN from './locales/en.json';
import translationEE from './locales/ee.json';
import translationRU from './locales/ru.json';
import translationLT from './locales/lt.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    ee: {
        translation: translationEE
    },
    ru: {
        translation: translationRU
    },
    lt: {
        translation: translationLT
    }
};

i18n
    .use(detector)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "ee", // use ee if detected lng is not available

        lng: localStorage.getItem("lang") || "ee",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;