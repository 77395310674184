import React from "react";
import Box from "@mui/material/Box";
import {lifecycle} from "recompose";
import CategoriesFilter from "../CategoriesFilter/CategoriesFilter";
import {withNamespaces} from "react-i18next";
import Marker from "react-google-maps/lib/components/Marker";
import TuneIcon from "@mui/icons-material/Tune";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import SearchInput from "../SearchInput/SearchInput";

const _ = require("lodash");

const {compose, withProps, withHandlers} = require("recompose");
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
} = require("react-google-maps");
const {MarkerClusterer} = require("react-google-maps/lib/components/addons/MarkerClusterer");
const {MarkerWithLabel} = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const {SearchBox} = require("react-google-maps/lib/components/places/SearchBox");


// const apiKey = AIzaSyCWCmCtA-8Ix1skFcf81DXdJZMLNcQmNyY
const apiKey2 = 'AIzaSyAkpZIh-wCLfUJdo5g33XdYKAHJCWu-WiY'

const MapWithAMarkerClusterer = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${apiKey2}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{height: `100%`}}/>,
        mapElement: <div style={{height: `100%`}}/>,
    }),
    withHandlers({
        onMarkerClustererClick: () => (markerClusterer) => {
            const clickedMarkers = markerClusterer.getMarkers()
            // console.log(`Current clicked markers length: ${clickedMarkers.length}`)
            // console.log(clickedMarkers)
        },
    }),
    lifecycle({
        componentWillMount() {
            const refs = {}

            this.setState({
                bounds: null,
                center: {
                    lat: 41.9, lng: -87.624
                },
                markers: [],
                onMapMounted: ref => {
                    refs.map = ref;

                    // initialize Autocomplete
                    const inputEl = document.getElementById('searchBox');

                    /*global google*/
                    const options = {
                        //types: ['address'],
                        componentRestrictions: {country: ['ee', 'lt']},
                    };
                    refs.autocomplete = new window.google.maps.places.Autocomplete(inputEl, options);
                    refs.autocomplete.addListener('place_changed', () => {
                        const place = refs.autocomplete.getPlace();
                        // console.log('place', place)
                        const bounds = new window.google.maps.LatLngBounds();


                        if (place.geometry.viewport) {
                            bounds.union(place.geometry.viewport)
                        } else {
                            bounds.extend(place.geometry.location)
                        }

                        const nextMarker = {
                            position: place.geometry.location,
                        }
                        const nextCenter = _.get(nextMarker, 'position', this.state.center);

                        this.props.setSearchPosition({
                            lat: nextCenter.lat(),
                            lng: nextCenter.lng(),
                        })

                        // this.setState({
                        //     center: nextCenter,
                        //     markers: nextMarkers,
                        // });
                        this.props.resetCenter?.()

                        refs.map.fitBounds(bounds);
                    });
                },
                onBoundsChanged: () => {
                    // console.log('onBoundsChanged', {
                    //     center: [
                    //         refs.map.getCenter().lat(),
                    //         refs.map.getCenter().lng(),
                    //     ],
                    //     center2: this.props.center2
                    // })

                    const lat = refs.map.getCenter()?.lat();
                    const lng = refs.map.getCenter()?.lng();
                    if (
                        this.props.center2 && this.props.center2.lat &&
                        lat && lng &&
                        (
                            lat.toFixed(3) !== this.props.center2.lat.toFixed(3) ||
                            lng.toFixed(3) !== this.props.center2.lng.toFixed(3)
                        )
                    ) {
                        // console.log('reset center2')
                        // this.props.setZoom?.(undefined)
                        // this.props.setCenter2?.(undefined)
                        this.props.resetCenter?.()
                    }

                    // this.setState({
                    //     bounds: refs.map.getBounds(),
                    //     center: refs.map.getCenter(),
                    // })
                },
                onSearchBoxMounted: ref => {
                    refs.searchBox = ref;
                },
                onPlacesChanged: () => {
                    // console.log('onPlacesChanged')
                    const places = refs.searchBox.getPlaces();
                    // console.log('places', places)
                    const bounds = new window.google.maps.LatLngBounds();

                    places.forEach(place => {
                        if (place.geometry.viewport) {
                            bounds.union(place.geometry.viewport)
                        } else {
                            bounds.extend(place.geometry.location)
                        }
                    });
                    const nextMarkers = places.map(place => ({
                        position: place.geometry.location,
                    }));
                    const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

                    this.props.setSearchPosition({
                        lat: nextCenter.lat(),
                        lng: nextCenter.lng(),
                    })

                        // this.setState({
                    //     center: nextCenter,
                    //     markers: nextMarkers,
                    // });
                    refs.map.fitBounds(bounds);
                },
            })
        },
    }),
    withScriptjs,
    withGoogleMap
)(props => (
        <GoogleMap
            ref={props.onMapMounted}
            onBoundsChanged={props.onBoundsChanged}
            defaultZoom={16}
            defaultCenter={{lat: 58.59, lng: 25.01}}
            // defaultCenter={props.center}
            options={{
                zoomControl: false,
                streetViewControl: false,
                fullscreenControl: false,
            }}
            {...props.center2 && {center: props.center2}}
            {...props.zoom && {zoom: props.zoom}}
        >
            {/*// decided to use Autocomplete, because SearchBox doesn't support restriction by country*/}
            {/*<SearchBox*/}
            {/*    ref={props.onSearchBoxMounted}*/}
            {/*    bounds={props.bounds}*/}
            {/*    controlPosition={window.google.maps.ControlPosition.TOP_RIGHT}*/}
            {/*    onPlacesChanged={props.onPlacesChanged}*/}
            {/*>*/}
                <div
                    style={{
                        display: 'flex',
                        width: 'calc(100% - 24px)',
                        maxWidth: `500px`,
                        marginTop: `70px`,
                        marginRight: '8px',
                        zIndex: 0,
                        right: 0,
                        top: 0,
                        position: 'absolute',
                    }}
                >
                    <SearchInput
                        categories={props.categories}
                        selectedCategories={props.selectedCategories}
                        handleToggleCategory={props.handleToggleCategory}
                    />
                </div>
            {/*</SearchBox>*/}

            <MarkerClusterer
                onClick={props.onMarkerClustererClick}
                averageCenter
                enableRetinaIcons
                // gridSize={60}
                minimumClusterSize={3}
            >
                {props.ownPosition && (
                    <Marker
                        position={props.ownPosition}
                        title={"You are here!"}
                        icon={{
                            fillColor: "#4285F4",
                            fillOpacity: 1,
                            path: window.google.maps.SymbolPath.CIRCLE,
                            scale: 8,
                            strokeColor: "rgb(255,255,255)",
                            strokeWeight: 2,
                        }}
                    />
                )}
                {props.locations.map((marker, key) => {

                    return (
                        <MarkerWithLabel
                            onClick={() => props.handleClickMarker?.(marker)}
                            icon={marker.icon}
                            // labelVisible={props.activeSlide && props.activeSlide.id === marker.id}
                            labelVisible={true}
                            key={marker.id}
                            position={{lat: Number(marker.lat), lng: Number(marker.lng)}}
                            labelAnchor={new window.google.maps.Point(55, -4)}
                            // scaledSize={new window.google.maps.Size(20, 20)}
                            labelStyle={{
                                textAlign: 'center',
                                width: "100px",
                                border: '1px solid purple',
                                backgroundColor: "aliceblue",
                                fontSize: "12px",
                                padding: "4px 8px",
                                borderRadius: "8px",
                                color: props.activeSlide && props.activeSlide.id === marker.id ? 'purple' : 'gray',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                            labelClass={"MarkerWithLabel-labelClass"}
                        >
                            <Box>{marker.title}</Box>
                        </MarkerWithLabel>
                    )
                })}
            </MarkerClusterer>
        </GoogleMap>
    )
);

export default React.memo(withNamespaces()(MapWithAMarkerClusterer))