import * as React from "react";
import {Routes, Route, Link} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import MemberCard from "./pages/MemberCard";
import {useEffect} from "react";

function App() {
    useEffect(() => {
        // window?.screen?.orientation?.lock("landscape").then(r => console.log('screen lock', r));
    }, [])
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/member-card" element={<MemberCard/>}/>
            </Routes>
        </div>
    );
}

export default App;