import React, {useState} from "react";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import CategoriesFilter from "../CategoriesFilter/CategoriesFilter";
import {withNamespaces} from "react-i18next";

const SearchInput = (props) => {
    const [active, setActive] = useState();

    return (
        <>
            <input
                onClick={() => setActive(true)}
                onBlur={() => {
                    setTimeout(() => {
                        setActive(false)
                    }, 200)
                }}
                id="searchBox"
                type="text"
                // placeholder="Search location..."
                placeholder={props.t("searchBox")}
                className="google-map-input search-icon"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: '100%',
                    height: `40px`,
                    padding: `0 36px`,
                    borderRadius: `8px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `16px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    transitionProperty: 'opacity',
                    transitionDuration: '200ms',
                    transitionTimingFunction: 'ease-in',
                }}
            />
            <IconButton
                onClick={() => {
                    const input = document.getElementById("searchBox")
                    if (input && input.value !== "") {
                        input.value = ""
                    }
                }}
                sx={{
                    position: 'absolute',
                    right: '8px',
                    color: '#653399',
                    display: active ? 'block' : 'none',
                }}
            >
                <Close/>
            </IconButton>
            <CategoriesFilter
                active={active}
                categories={props.categories}
                selectedCategories={props.selectedCategories}
                handleToggleCategory={props.handleToggleCategory}
            />
        </>
    )
}

export default withNamespaces()(SearchInput);