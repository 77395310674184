import React, { useEffect, useState } from "react";
// import Slider from "react-slick";
import Box from "@mui/material/Box";
import "./index.scss";
import Typography from "@mui/material/Typography";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DateRangeIcon from "@mui/icons-material/DateRange";
import InfoIcon from "@mui/icons-material/Info";
import MapIcon from "@mui/icons-material/Map";
import Swiper from "react-id-swiper";
import Button from "@mui/material/Button";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import FeedbackIcon from "@mui/icons-material/Feedback";
import Chip from "@mui/material/Chip";
import { withNamespaces } from "react-i18next";

const SliderLocations = (props) => {
  const [swiper, setSwiper] = useState(null);

  const { currentSlide, setCurrentSlide = (index) => {} } = props;

  useEffect(() => {
    if (swiper && currentSlide !== -1) {
      // console.log('slideTo', currentSlide)
      swiper.slideTo(Number(currentSlide));
    }
  }, [currentSlide]);

  let settings = {
    afterChange: (current) => {
      console.log("current slide", current);
      setCurrentSlide(current);
    },
    infinite: false,
    speed: 500,
    slidesToShow: 1.1,
    centerMode: false,
    focusOnSelect: true,
    // slidesToScroll: 1,
    initialSlide: 0,
    // centerPadding: 0,
  };

  let settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    // initialSlide: 0,
    centerMode: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          // infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 0,
          centerMode: true,
        },
      },
    ],
  };

  let params = {
    on: {
      init: function (ref) {
        setSwiper(ref);
      },
      activeIndexChange: ({ activeIndex }) => {
        setCurrentSlide(activeIndex);
      },
    },
    slidesPerView: 3,
    spaceBetween: 30,
    centeredSlides: false,
    // centeredSlidesBounds: true,
    slideToClickedSlide: true,
    slidesOffsetBefore: 20,
    slidesOffsetAfter: 20,

    shouldSwiperUpdate: true,

    breakpoints: {
      1024: {
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        slidesPerView: 3.2,
        spaceBetween: 20,
        centeredSlides: false,
      },
      768: {
        slidesPerView: 2.5,
        spaceBetween: 15,
        centeredSlides: false,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        // centeredSlidesBounds: true,
        // initialSlide: 0,
      },
      640: {
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        slidesPerView: 2,
        spaceBetween: 15,
        centeredSlides: true,
      },
      320: {
        slidesPerView: 1.2,
        spaceBetween: 10,
        centeredSlides: true,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      },
    },
  };

  const { locations = [] } = props;

  return (
    <Box position="fixed" zIndex={1} bottom={20} right={0} left={0}>
      <Swiper {...params}>
        {locations.map((location, key) => {
          const objectHours = JSON.parse(location.open_hours);

          let openHours = "";

          if (Object.entries(objectHours).every((el) => el[1] == 1)) {
            openHours = "24/24";
          } else if (objectHours?.mon?.[0]) {
            openHours = objectHours?.mon?.[0];
          }

          let days = "";

          if (location.days_str.split(",").length === 7) {
            days = "7/7";
          } else {
            days = location.days_str;
          }

          return (
            <div className="slide" key={key}>
              <Box
                className="location-card"
                minHeight={"inherit"}
                py={1.5}
                px={1.5}
                color={"purple"}
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection={"column"}
              >
                <Box className="card-details">
                  <Typography variant="body1" sx={{ mb: 1 }} fontWeight={500}>
                    {location.title}
                  </Typography>
                  {location.distance && (
                    <Typography variant="body2" className="location-distance">
                      {location.distance.toFixed(1)} {props.t("km") || "km"}
                    </Typography>
                  )}
                  {location.categories?.map((category) => (
                    <Chip
                      key={category.id}
                      label={props.t(
                        `${category.category_name}`,
                        category.category_name
                      )}
                      color="secondary"
                      size="small"
                      className="badge"
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                  {days && (
                    <Typography
                      variant="body2"
                      sx={{ mb: 0.5 }}
                      display={"flex"}
                      alignItems="center"
                    >
                      <DateRangeIcon sx={{ mr: 1 }} />
                      <Box component={"span"}>{days}</Box>
                    </Typography>
                  )}
                  {openHours && openHours !== "0" && (
                    <Typography
                      variant="body2"
                      sx={{ mb: 0.5 }}
                      display={"flex"}
                      alignItems="center"
                    >
                      <AccessTimeIcon sx={{ mr: 1 }} />{" "}
                      <Box component={"span"}>{openHours}</Box>
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    sx={{ mb: 1 }}
                    display={"flex"}
                    alignItems="center"
                  >
                    <MapIcon sx={{ mr: 1 }} />{" "}
                    <Box component={"span"}>
                      {location.street} {location.city}
                    </Box>
                  </Typography>

                  {location.description && (
                    <Typography
                      variant="body2"
                      sx={{ mb: 1 }}
                      display={"flex"}
                      alignItems="center"
                    >
                      <InfoIcon sx={{ mr: 1 }} />{" "}
                      <Box component={"span"}>{location.description}</Box>
                    </Typography>
                  )}
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                >
                  <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    className="card-actions"
                  >
                    <Button
                      className="action-btn"
                      startIcon={<AssistantDirectionIcon />}
                      sx={{
                        color: "purple",
                      }}
                      onClick={() => {
                        window.open(
                          `https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`,
                          "_blank"
                        );
                      }}
                    >
                      {/*Get directions*/}
                      {props.t("getDirections") || "Get directions"}
                    </Button>
                  </Box>
                  <Button
                    className="action-btn"
                    startIcon={<FeedbackIcon />}
                    // sx={{
                    //   color: "purple",
                    // }}
                    href={`mailto:wc@ibd.ee?subject=${location.title}, ${location.street} ${location.city}&body=feedback`}
                  >
                    {props.t("feedback")}
                  </Button>
                </Box>
              </Box>
            </div>
          );
        })}
      </Swiper>
      {/*<Slider {...settings}>*/}
      {/*    {locations.map((location, key) => {*/}
      {/*        console.log("open_hours", JSON.parse(location.open_hours))*/}

      {/*        const objectHours = JSON.parse(location.open_hours);*/}

      {/*        let openHours = ''*/}

      {/*        if (Object.entries(objectHours).every((el) => el[1] == 1)) {*/}
      {/*            openHours = '24/24'*/}
      {/*        } else if (objectHours?.mon?.[0]) {*/}
      {/*            openHours = objectHours?.mon?.[0]*/}
      {/*        }*/}

      {/*        let days = '';*/}

      {/*        if (location.days_str.split(',').length === 7) {*/}
      {/*            days = '7/7'*/}
      {/*        } else {*/}
      {/*            days = location.days_str*/}
      {/*        }*/}

      {/*        return (*/}
      {/*            <div className="slide" key={key}>*/}
      {/*                <Box py={2} px={2} color={"purple"}>*/}
      {/*                    <Typography variant="body1" sx={{ mb: 1 }} fontWeight={500}>{location.title}</Typography>*/}
      {/*                    {days && (*/}
      {/*                        <Typography variant="body2" sx={{ mb: 1 }} display={"flex"} alignItems="center"><DateRangeIcon sx={{ mr: 1 }}/> <Box component={"span"}>{days}</Box></Typography>*/}
      {/*                    )}*/}
      {/*                    {openHours && openHours !== '0' && (*/}
      {/*                        <Typography variant="body2" sx={{ mb: 1 }} display={"flex"} alignItems="center"><AccessTimeIcon sx={{ mr: 1 }}/> <Box component={"span"}>{openHours}</Box></Typography>*/}
      {/*                    )}*/}
      {/*                    <Typography variant="body2" sx={{ mb: 1 }} display={"flex"} alignItems="center"><MapIcon sx={{ mr: 1 }}/> <Box component={"span"}>{location.street} {location.city}</Box></Typography>*/}

      {/*                    {location.description && (*/}
      {/*                        <Typography variant="body2" sx={{ mb: 1 }} display={"flex"} alignItems="center"><InfoIcon sx={{ mr: 1 }}/> <Box component={"span"}>{location.description}</Box></Typography>*/}
      {/*                    )}*/}
      {/*                </Box>*/}
      {/*            </div>*/}
      {/*        )*/}
      {/*    })}*/}
      {/*</Slider>*/}
    </Box>
  );
};

export default withNamespaces()(SliderLocations);
