import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Header from "../components/Header/Header";
import Map2 from "../components/Map/Map2";
import Box from "@mui/material/Box";
import SliderLocations from "../components/SliderLocations/SliderLocations";
import { getDistanceFromLatLonInKm } from "../helpers/distance";

import ajutineIcon from "../images/markers/ajutine-wc.png";
import avalicIcon from "../images/markers/avalik-wc.png";
import invaIcon from "../images/markers/inva-wc-ikoon-3.png";
import kaardigaIcon from "../images/markers/kaardiga-wc.png";
import tasulineIcon from "../images/markers/tasuline-wc.png";
import IconButton from "@mui/material/IconButton";
import NavigationIcon from "@mui/icons-material/Navigation";

const Home = () => {
  const [myTimeout, setMyTimeout] = useState(0);
  const [navigatorWatchId, setNavigatorWatchId] = useState();
  const [distanceCalculated, setDistanceCalculated] = useState(false);
  const [ownPosition, setOwnPosition] = useState();
  const [searchPosition, setSearchPosition] = useState();

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [center2, setCenter2] = useState(undefined);
  const [zoom, setZoom] = useState(undefined);

  const [sortedByOwnPosition, setSortedByOwnPosition] = useState(false);

  const ownPositionRef = useRef({
    lat: 0,
    lng: 0,
  });

  const handleToggleCategory = useCallback(
    (value) => () => {
      const currentIndex = selectedCategories.indexOf(value);
      const newChecked = [...selectedCategories];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setSelectedCategories(newChecked);
    },
    [selectedCategories, setSelectedCategories]
  );

  const [locations, setLocations] = useState([]);
  const [sortedLocations, setSortedLocations] = useState([]);

  const [finalLocations, setFinalLocations] = useState([]);

  const [currentSlide, setCurrentSlide] = useState(0);

  const getIcon = (id) => {
    if (id == 22) return tasulineIcon;
    if (id == 21) return ajutineIcon;
    if (id == 20) return kaardigaIcon;
    if (id == 19) return avalicIcon;
    if (id == 23) return invaIcon;
    return undefined;
  };

  function ConvertDMSToDD(degrees, minutes, seconds, direction) {
    let dd = +degrees + minutes / 60 + seconds / (60 * 60);

    if (direction == "S" || direction == "W") {
      dd = dd * -1;
    } // Don't do anything for N or E
    return dd;
  }

  useEffect(() => {
    return () => {
      if (navigatorWatchId) {
        navigator.geolocation.clearWatch(navigatorWatchId);
      }
    };
  }, []);

  useEffect(() => {
    if (ownPosition) {
      ownPositionRef.current = ownPosition;
    }
  }, [ownPosition]);

  useEffect(() => {
    const resize = () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    // We listen to the resize event
    window.addEventListener("resize", resize);

    fetch("https://ibd.ee/wp-json/custom/v1/locations")
      .then((res) => res.json())
      .then(({ locations, categories }) => {
        // console.log("locations", locations)
        // console.log("categories", categories)
        setCategories(categories);
        setLocations([
          ...locations.map((location) => {
            const categoriesIds = location.categories?.split(",");
            let lat;
            let lng;
            if (location.lat.includes("°")) {
              let parts = location.lat.split(/[^\d\w]+/);
              lat = ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3]);
            }
            if (location.lng.includes("°")) {
              let parts = location.lng.split(/[^\d\w]+/);
              lng = ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3]);
            }

            return {
              ...location,
              categories: categories.filter(
                (category) =>
                  categoriesIds && categoriesIds.includes(category.id)
              ),
              icon: getIcon(categoriesIds?.[0]),
              lat: lat || location.lat,
              lng: lng || location.lng,
            };
          }),
        ]);
      });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          let pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setOwnPosition(pos);
        },
        () => {
          //set a default location
          setOwnPosition({
            lat: 58.59,
            lng: 25.01,
          });
        }
      );

      //watch geolocation
      const id = navigator.geolocation.watchPosition(
        (pos) => {
          const crd = pos.coords;
          // set own position only if it's different - solves many updates problem
          const ownPosition = ownPositionRef.current;

          if (!ownPosition || !ownPosition.lat) {
            setOwnPosition({
              lat: crd.latitude,
              lng: crd.longitude,
            });
          } else if (
            ownPosition.lat.toFixed(4) !== crd.latitude.toFixed(4) ||
            ownPosition.lng.toFixed(4) !== crd.longitude.toFixed(4)
          ) {
            setOwnPosition({
              lat: crd.latitude,
              lng: crd.longitude,
            });
          }
        },
        (err) => {
          console.warn("ERROR(" + err.code + "): " + err.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
      setNavigatorWatchId(id);
    } else {
      //set a default location
      setOwnPosition({
        lat: 58.59,
        lng: 25.01,
      });
    }

    return () => {
      // unmount component
      window.removeEventListener("resize", resize);
    };
  }, []);

  // filter by own position
  useEffect(() => {
    if (ownPosition && !distanceCalculated && locations.length) {
      setDistanceCalculated(true);
      setSortedByOwnPosition(true);

      // last feedback - own position on first screen
      setCenter2(ownPosition);

      setSortedLocations(
        locations
          .map((location) => ({
            ...location,
            distance: getDistanceFromLatLonInKm(
              ownPosition.lat,
              ownPosition.lng,
              location.lat,
              location.lng
            ),
          }))
          .sort(function (a, b) {
            return a.distance - b.distance;
          })
      );
    }
  }, [ownPosition, locations]);

  // filter again for watch live position if not searchPosition
  useEffect(() => {
    if (
      ownPosition &&
      distanceCalculated &&
      locations.length &&
      !searchPosition
    ) {
      setCenter2(ownPosition);

      if (myTimeout) {
        // clearTimeout(myTimeout);
        // setMyTimeout(0);
      }

      const timeout = setTimeout(() => {
        setSortedLocations(
          locations
            .map((location) => ({
              ...location,
              distance: getDistanceFromLatLonInKm(
                ownPosition.lat,
                ownPosition.lng,
                location.lat,
                location.lng
              ),
            }))
            .sort(function (a, b) {
              return a.distance - b.distance;
            })
        );
      }, 2000);
      setMyTimeout(timeout);
    }
  }, [ownPosition]);

  // filter by search point position
  useEffect(() => {
    if (searchPosition && locations.length) {
      setDistanceCalculated(true);
      setSortedByOwnPosition(false);

      setSortedLocations(
        locations
          .map((location) => ({
            ...location,
            distance: getDistanceFromLatLonInKm(
              ownPosition.lat || searchPosition.lat,
              ownPosition.lng || searchPosition.lng,
              location.lat,
              location.lng
            ),
            distance2: getDistanceFromLatLonInKm(
              searchPosition.lat,
              searchPosition.lng,
              location.lat,
              location.lng
            ),
          }))
          .sort(function (a, b) {
            return a.distance2 - b.distance2;
          })
      );
    }
  }, [searchPosition]);

  useEffect(() => {
    setFinalLocations((prev) => {
      if (!selectedCategories || selectedCategories.length === 0) {
        return sortedLocations.slice(0, 20);
      }
      return sortedLocations
        .filter((location) => {
          return location.categories.some((category) =>
            selectedCategories.includes(category.id)
          );
        })
        .slice(0, 20);
    });
  }, [sortedLocations, selectedCategories]);

  useEffect(() => {
    if (currentSlide !== 0) {
      setCurrentSlide(0);
    }
  }, [finalLocations]);

  const activeSlide = useMemo(() => {
    return finalLocations && finalLocations[currentSlide];
  }, [finalLocations, currentSlide]);

  const handleClickMarker = useCallback(
    (marker) => {
      // console.log('handleClickMarker', marker);
      let index = finalLocations.findIndex((el) => el.id == marker.id);
      if (index !== -1) {
        setCurrentSlide(index);
        return;
      }
      // add missing location to finalLocations
      index = sortedLocations.findIndex((el) => el.id == marker.id);

      if (index !== -1) {
        // setCurrentSlide(index);
        setFinalLocations((prevState) => [
          ...prevState,
          sortedLocations[index],
        ]);
        setCurrentSlide(finalLocations.length);
      }
    },
    [finalLocations, sortedLocations]
  );

  const handleClickUserLocation = useCallback(() => {
    const input = document.getElementById("searchBox");
    if (input && input.value !== "") {
      input.value = "";
    }
    if (searchPosition) {
      setSearchPosition(undefined);
    }
    if (ownPosition) {
      setTimeout(() => {
        // apply own position after locations are sorted
        setCenter2(ownPosition);
        setZoom(16);
        setCurrentSlide(0);
      }, 200);

      if (!sortedByOwnPosition) {
        setSortedByOwnPosition(true);
        setSortedLocations(
          locations
            .map((location) => ({
              ...location,
              distance: getDistanceFromLatLonInKm(
                ownPosition.lat,
                ownPosition.lng,
                location.lat,
                location.lng
              ),
            }))
            .sort(function (a, b) {
              return a.distance - b.distance;
            })
        );
      }
    }
  }, [ownPosition, locations, sortedByOwnPosition]);

  const resetCenter = useCallback(() => {
    // setTimeout(() => {
    setZoom(undefined);
    setCenter2(undefined);
    // }, 200)
  }, []);

  useEffect(() => {
    const onOpenApp = () => {
      if (document.visibilityState === "visible") {
        handleClickUserLocation();
      }
    };

    document.addEventListener("visibilitychange", onOpenApp);

    return () => {
      document.removeEventListener("visibilitychange", onOpenApp);
    };
  }, [handleClickUserLocation]);

  // render
  return (
    <>
      <Header />

      {/*<Search/>*/}

      {/*<Map/>*/}

      {ownPosition && (
        <Box position="fixed" zIndex={100} bottom={264} right={10}>
          <IconButton
            onClick={handleClickUserLocation}
            style={{
              backgroundColor: "white",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            }}
          >
            <NavigationIcon
              style={{
                color: "mediumslateblue",
                transform: "rotate(45deg)",
              }}
            />
          </IconButton>
        </Box>
      )}

      {finalLocations && (
        <SliderLocations
          locations={finalLocations}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
        />
      )}

      {locations.length && (
        <Box position="fixed" top={0} bottom={0} left={0} right={0}>
          <Map2
            resetCenter={resetCenter}
            zoom={zoom}
            ownPosition={ownPosition}
            categories={categories}
            locations={locations}
            activeSlide={activeSlide}
            center2={center2}
            handleClickMarker={handleClickMarker}
            selectedCategories={selectedCategories}
            handleToggleCategory={handleToggleCategory}
            setSearchPosition={setSearchPosition}
          />
        </Box>
      )}
    </>
  );
};

export default Home;
