import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import {
  Alert,
  Card,
  CircularProgress,
  Container,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { withNamespaces } from "react-i18next";
import "./index.scss";
import logo from "../images/logo.png";
import icons from "../images/icons.png";

function MemberCard({ t }) {
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [verified, setVerified] = useState();

  const verifyEmail = () => {
    setLoading(true);
    setVerified(undefined);
    setName("");
    setCreatedAt("");
    fetch(`https://ibd.ee/wp-json/apiex/v1/email/${email}`)
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (!res) {
          setVerified(false);
          return;
        }
        localStorage.setItem("email", email);
        setVerified(true);
        if (res.name) {
          setName(res.name);
        }
        if (res.created_at) {
          setCreatedAt(res.created_at);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (email) {
      verifyEmail();
    }
  }, []);

  const handleChange = (e) => {
    e.persist();

    setVerified(undefined);
    setEmail(e.target.value);
  };

  const handleVerify = () => {
    verifyEmail();
  };

  return (
    <>
      <Header />

      <Container>
        <Box mt={10}>
          <Box mb={2}>
            <Typography variant="body1">{t("memberCard")}</Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <TextField
              id="email"
              type="email"
              label={t("email")}
              placeholder={t("emailPlaceholder")}
              variant="outlined"
              fullWidth
              // autoFocus={!!email}
              //value={email}
              onChange={handleChange}
              size={"small"}
              sx={{
                marginRight: 1,
              }}
            />
            <Button
              onClick={handleVerify}
              variant={"contained"}
              disabled={!email}
              color={"secondary"}
            >
              {t("verify")}
            </Button>
          </Box>
          {loading && (
            <Box mt={5} display={"flex"} justifyContent={"center"}>
              <CircularProgress />
            </Box>
          )}
          {verified !== undefined && (
            <Box mt={2}>
              {verified && (
                <>
                  {/* <Box display={"flex"} justifyContent={"center"}>
                    <Alert severity="success">{t("emailSuccess")}</Alert>
                  </Box> */}
                  <Box
                    mt={2}
                    display={"flex"}
                    justifyContent={"center"}
                    color={"purple"}
                  >
                    {/* <Card variant={"elevation"} sx={{ p: 2, width: 260 }}>
                      {email && (
                        <Typography variant={"body2"}>
                          {t("email")}: {email}
                        </Typography>
                      )}
                      {name && (
                        <Typography variant={"body2"}>
                          {t("name")}: {name}
                        </Typography>
                      )}
                      {createdAt && (
                        <Typography variant={"body2"}>
                          {t("createdAt")}: {createdAt}
                        </Typography>
                      )}
                    </Card> */}
                    <div className="card">
                      <div className="card-header">
                        <span>WC kaart</span>
                      </div>
                      <div className="card-body">
                        <span className="text">
                          Palun lubage mind tervise eripära tõttu viivitamatult
                          WC-sse. Aitäh!
                        </span>
                      </div>
                      <div className="card-footer">
                        <div className="toilet-icons">
                          <img
                            clasName="icons"
                            src={icons}
                            alt="toilet-icons"
                            style={{ width: "120px" }}
                          />
                        </div>
                        <div className="logo">
                          <img
                            clasName="logo-image"
                            src={logo}
                            alt="logo"
                            style={{ width: "120px" }}
                          />
                          <span>Eesti Põletikulise Soolehaiguse Selts</span>
                        </div>
                      </div>
                    </div>
                  </Box>
                </>
              )}
              {verified === false && (
                <Alert severity="error">{t("emailError")}</Alert>
              )}
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
}

export default withNamespaces()(MemberCard);
