import React from "react";
import TuneIcon from "@mui/icons-material/Tune";
import IconButton from "@mui/material/IconButton";
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {withNamespaces} from "react-i18next";

const CategoriesFilter = ({ active = false, t, categories = [], selectedCategories = [], handleToggleCategory = () => {} }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconButton
                onClick={handleClick}
                sx={{
                    position: 'absolute',
                    right: '8px',
                    color: '#653399',
                    display: !active ? 'block' : 'none',
                }}
            >
                <TuneIcon/>
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                // anchorReference="anchorPosition"
                // anchorPosition={{ top: 120, left: 12 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    "& .MuiPaper-root": {
                        marginTop: 1,
                        borderRadius: "8px",
                    }
                }}
            >
                {/*<Typography sx={{ p: 2 }}>Categories</Typography>*/}
                <List
                    width={"100%"}
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {/*Categories*/}
                            {t("filters")}
                        </ListSubheader>
                    }
                >
                    {categories.map((category) => (
                        <ListItemButton
                            onClick={handleToggleCategory(category.id)}
                            sx={{ py: 0, }}
                            key={category.id}
                            selected={selectedCategories.includes(category.id)}
                            // onClick={(event) => handleListItemClick(event, 0)}
                        >
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <Checkbox
                                    edge="start"
                                    checked={selectedCategories.includes(category.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': category.id }}
                                    sx={{
                                        "&.Mui-checked .MuiSvgIcon-root": {
                                            color: '#653399',
                                        },
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={category.id} primary={t(`${category.category_name}`, category.category_name)} />
                        </ListItemButton>
                    ))}
                </List>
            </Popover>
        </>
    )
}

export default withNamespaces()(CategoriesFilter);