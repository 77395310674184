import React from "react";
import MapIcon from "@mui/icons-material/Map";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from '@mui/icons-material/Language';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import i18n from '../../i18n';

const LanguageSwitcher = () => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lng) => {
        localStorage.setItem("lang", lng);
        i18n.changeLanguage(lng);
        handleClose();
    }

    return (
        <>
            <Button
                // size="large"
                size="small"
                edge="start"
                color="inherit"
                aria-label="lang"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                sx={{
                    width: '64px',
                }}
            >
                {/*<LanguageIcon />*/}
                <Typography>{i18n.language || "En"}</Typography>
                <KeyboardArrowDownIcon sx={{
                    transition: 'transform .2s ease-in-out',
                    transform: Boolean(anchorEl) ? 'rotate(180deg)' : 'none',
                }} />
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem selected={i18n.language === 'en'} onClick={() => changeLanguage('en')}>
                    EN
                </MenuItem>
                <MenuItem selected={i18n.language === 'ee'} onClick={() => changeLanguage('ee')}>
                    EE
                </MenuItem>
                <MenuItem selected={i18n.language === 'ru'} onClick={() => changeLanguage('ru')}>
                    RU
                </MenuItem>
                <MenuItem selected={i18n.language === 'lt'} onClick={() => changeLanguage('lt')}>
                    LT
                </MenuItem>
            </Menu>
        </>
    )
}

export default LanguageSwitcher;