import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MapIcon from '@mui/icons-material/Map';
import {useNavigate} from "react-router-dom";

import {withNamespaces} from 'react-i18next';
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import {Link} from "react-router-dom";

function Header({t}) {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="fixed" sx={{backgroundColor: "rebeccapurple"}}>
                <Toolbar>
                    <IconButton
                        id="burger-button"
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{mr: 2}}
                        onClick={handleClick}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Menu
                        id="burger-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'burger-button',
                        }}
                    >
                        <MenuItem component={Link} to="/member-card">{t('memberCard')}</MenuItem>
                    </Menu>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{flexGrow: 1, cursor: 'pointer'}}
                        onClick={() => navigate('/')}
                    >
                        {t("header")}
                        {/*WC KAART*/}
                    </Typography>
                    {/*<IconButton*/}
                    {/*    size="large"*/}
                    {/*    edge="start"*/}
                    {/*    color="inherit"*/}
                    {/*    aria-label="map"*/}
                    {/*>*/}
                    {/*    <MapIcon />*/}
                    {/*</IconButton>*/}
                    <LanguageSwitcher/>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default withNamespaces()(Header);